export const workExperience = [
  {
    company: "Power Digital Marketing",
    position: "Junior Software Engineer",
    duration: "August 2022 - January 2025",
    description:
      "Developed and maintained a full-stack expense tracking app with AI integration for receipt scanning, real-time budget tracking, data visualization, and user authentication.",
    technologies: ["TypeScript", "React.js", "RTK Query", "AWS"],
    image: "powerdigitalmarketing",
    location: "San Diego, CA (Remote)",
    responsibilities: [
      "Enhanced web solutions for a $100M+ company by optimizing scalability, performance, and user experience using React, TypeScript, Redux Toolkit, and GraphQL.",
      "Collaborated with cross-functional teams to develop a AI-powered component for querying Looker metrics, enhancing data-driven decision-making processes.",
      "Contributed to and implemented a reusable component library with Material-UI (MUI) and React Testing Library, ensuring consistent development workflows and streamlined implementation.",
      "Introduced Retool to facilitate rapid MVP prototyping, streamlining development workflows and expediting client-driven solutions.",
      "Increased test coverage by 10% by developing and maintaining component unit tests with React Testing Library, enhancing code stability.",
      "Collaborated with QA and cross-functional teams to implement testing and feedback processes that reduced production bugs by 30%.",
    ],
  },
];

export const featuredProject = {
  image: ["flow", "flow2", "flow3", "flow4", "flow5", "flow6"],
  name: "Flow",
  technologies: [
    "TypeScript",
    "React.js",
    "Next.js",
    "Tailwind CSS",
    "Supabase",
  ],
  demo: "https://flow-ivory-rho.vercel.app/",
  code: "https://github.com/anthonypang/flow",
  description:
    "Constructed a full-stack expense tracking app featuring AI integration for receipt scanning with 95% accuracy, real-time budget tracking across 10+ categories, data visualization, and user authentication.",
};

export const projectItems = [
  {
    image: "githubfinder",
    name: "Github Finder",
    technologies: ["React.js"],
    link: "https://githubfinder-anthonypang.netlify.app/",
    description:
      "A React-powered web application that allows users to search for GitHub profiles and view detailed information, including repositories, followers, and activity, by leveraging the GitHub API. Features include a dynamic search bar and real-time data fetching.",
  },

  {
    image: "keeper",
    name: "Keeper",
    technologies: ["React.js"],
    link: "https://keeper-anthonypang.netlify.app/",
    description:
      "A sleek, note-taking application built with React that allows users to create, edit, and delete notes. Features include a dynamic note input form and real-time note updating saved to local state.",
  },

  {
    image: "tasktracker",
    name: "Task Tracker",
    technologies: ["React.js"],
    link: "https://clever-speculoos-362101.netlify.app/",
    description:
      "A React-based task tracking application designed to help users organize and manage their daily tasks efficiently. Features include task creation, editing, and deletion, as well as the ability to mark tasks.",
  },
];

export const skillItems = [
  {
    skill: "JavaScript",
    image: "JavaScript-logo",
  },
  {
    skill: "TypeScript",
    image: "typescript",
  },
  {
    skill: "React",
    image: "react",
  },

  {
    skill: "Node.js",
    image: "node",
  },

  {
    skill: "git",
    image: "git",
  },
  {
    skill: "HTML",
    image: "html5",
  },
  {
    skill: "CSS",
    image: "css3",
  },
];

export const navItems = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "about",
    title: "About",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "experience",
    title: "Experience",
  },
  {
    id: "contact",
    title: "Contact",
  },
];
